const ar = {
	common: {
		login: "تسجيل الدخول",
		UserRegistration: "تسجيل مستخدم",
		ResetPassword: "إعادة تعيين كلمة المرور",
		Confirm: "تأكيد",
		Cancel: "إلغاء",
		Edit: "تعديل",
		PhoneNumber: "رقم الهاتف",
		Email: "البريد الإلكتروني",
		UserName: "اسم المستخدم",
		Password: "كلمة السر",
		VerificationCode: "رمز التحقق",
		ConfirmPassword: "تأكيد كلمة المرور",
		PleaseEnterThe: "الرجاء إدخال",
		EmailAlreadyExists:
			"There is already an account associated with the email ",
		PleaseLoginBindEmail:
			" Please log in and bind the third-party account in the personal center.",
		YouarevisitingDNAChronInternational:
			"You are visiting DNAChron International. We suggest you to visit DNAChron China according to your IP address.",
		Jumpto: "Jump to",
		Noprompt: "No prompt",
		yes: "yes",
		no: "no",
		Save: "حفظ . ",
		Wearesorrythepageyourequestedcannotbefound:
			"We are sorry, the page you requested cannot be found.",
		BacktoHome: "Back to Home",
	},
	navbar: {
		YTree: "شجرة Y",
		Ancestry: "النسب",
		ImportSample: " استيراد البيانات",
		Whatnews: "تجديد",
		SignIn: "تسجيل الدخول",
		SignUp: "تسجيل",
		Notification: "إخطار",
		PrivateMessage: "رسالة خاصة",
		AncestralTools: " المصدر أداة ",
		BrowseConfig: "تصفح التكوين",
		PersonalCenter: "المركز الشخصي",
		IndividualInformation: "معلومات الشخص الفاحص",
		SequenceData: "بيانات التسلسل",
		Account: "حساب",
		Logout: "خروج الدخول",
		TreeBrowseConfig: "Tree Browse Config",
		nodescountperpage: "nodes count per page",
		showdiscloseinformation: "Show Disclose Information",
		youcanviewyour:
			"You can view your private and authorized information after sign in.",
		showindividualname: "Show Individual Name",
		language: "اللغة",
	},
	index: {
		Trace: "تتبع البصمة التاريخية للبشر",
		Tracethehuman: `"تحليل بيانات التسلسل الجيني باستخدام خوارزميات متقدمة وتحديد موقعك المحدد "
"على شجرة الأب البشرية ، وتتبع رحلة الإنسان مع المستخدمين حول العالم."`,
		Joinnow: "استكشاف شجرة الأنساب الأبوية",
		Makeefficient: "الاستخدام الفعال لبيانات التسلسل",
		Ychromosomedata: `يمكن لتحليل بيانات صبغ Y استخدام بيانات التسلسل 18 مليونًا بشكل تكيفي ولا 
يقتصر على الفاصل الزمني لتقدير العمر التقليدي (8 ملايين) يمكن أن يعطي أيضًا 
نطاق واحتمالية الشرئح المختلفة ؛ كلما زادت تغطية بيانات التسلسل ، زادت دقة 
تقدير العمر بالنسبة للجينوم بأكمله بالنسبة للعينات أو الفروع ، يمكن أيضًا 
استخدام INDEL و MNP لزيادة دقة تقدير العمر.`,
		Preciseanalysis: "تحليل دقيق",
		Basedon:
			"بموجب الاستخدام الفعال لبيانات التسلسل ، يمكننا العثور على المزيد من الطفرات والتي تساعد في توضيح شكل الشجرة لكل فرع من فروع الأبوه  وخاصة الفرع المتفشي بقوة ودعم عرض التغطية لتحليل موضع شجرة Y بدقة.",
		Faston: "تسلق الشجرة بسرعة ",
		Afterthe:
			"بعد أن يقومو الموظفون تنزيل البيانات المقدمة من المستخدم بنجاح ، يمكن إكمال التحليل في غضون 1-3 أيام عمل وعرضه على شجرة التمايز الأبوي بشرط عدم وجود شذوذ خاص في البيانات ، وبالتالي تقليل وقت الانتظار وقلق الانتظار بقدر المستطاع.",
		Convenientquery: "شجرة isogg المريحة للاستعلام",
		Fortheisogg:
			"بالنسبة إلى أرقام isogg المستخدمة في الأوراق ومؤسسات الاختبار ومؤسسات البحث العلمي ، وما إلى ذلك ، فإننا نقدم شجرة تمايز isogg واستعلام رقم لتلبية احتياجات الاستعلام الخاصة بك.",
		PrivacySecurity: "ضمان أمان الخصوصية",
		Wewill:
			"سنعمل على تخزين معلومات التسجيل الخاصة بك ومعلومات الاشخاص الفاحصين ، ومعلومات نتائج التحليل وبيانات المعلومات الجينية بشكل منفصل على السحابة ، ونحميها بشكل شامل من خلال تدابير أمنية معقولة وممكنة مثل عمليات التكنولوجيا والأجهزة والإدارة. ",
		exploreAncestral: "استكشاف الأنساب",
		ISOGGTree: "شجرة ISOGG",
		YTree: "شجرة Y",
		ImportData: "استيراد البيانات",
	},
	Footer: {
		FAQ: "مشكلة شائعة",
		Blog: "مدونة",
		CustomerService: "البريد الإلكتروني لخدمة العملاء",
		Officialcommunication: "Official communication group",
		AboutUs: "معلومات عنا",
		TermofService: "شروط الخدمة",
		PrivacyPolicy: "سياسة الخصوصية",
		ContactCustomerService: "اتصل بخدمة العملاء",
		ContactService: "Message Customer Service",
		InternationalSite: "International Site",
		ChinaSite: "China Site",
		Site: "موقع",
	},
	whatnews: {
		CreateDate: "Create Date",
		UpdateDate: "Update Date",
		YHaplogroup: "Y-HG",
		MTHaplogroup: "MT-HG",
		EstimatedAge: "تقدير العمر",
		Surname: "اللقب",
		FirstName: "الاسم",
		BirthYear: "سنة الميلاد",
		Period: "الدولة أو ثقافي",
		Origin: "Origin",
		Site: "Site",
		Population: "جنسية",
	},
	statusTips: {
		0: "نجاح",
		10010: "كلمة المرور خاطئة",
		10070: "اسم المستخدم غير صحيح",
	},
};

export default ar;
