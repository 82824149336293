import { createI18n } from "vue-i18n";
import App from "../../src/App.vue";
import ar from "./ar";
import en from "./en";
import zh_hans from "./zh_hans";

import { LangAndBasePathObj } from "@/utils/index";
console.log(import.meta.env.VITE_APP_SITE_ENV, "哈哈哈");
let lang = LangAndBasePathObj(
	import.meta.env.VITE_APP_SITE_ENV,
	window.location.origin,
);

const i18n = createI18n({
	legacy: false, // set legacy mode
	globalInjection: true,
	// locale: localStorage.getItem("lang") || "en", // set locale
	locale: lang.lang || "en", // set locale
	messages: {
		ar,
		en,
		"zh-hans": zh_hans,
	},
});

export default i18n;
