const en = {
	common: {
		login: "Sign In",
		UserRegistration: "Create Account",
		ResetPassword: "Reset Password",
		Confirm: "Confirm",
		Cancel: "Cancel",
		Edit: "Edit",
		PhoneNumber: "Phone Number",
		Email: "Email",
		UserName: "Username",
		Password: "Password",
		VerificationCode: "Verification Code",
		ConfirmPassword: "Confirm Password",
		PleaseEnterThe: "Please enter the",
		EmailAlreadyExists:
			"There is already an account associated with the email ",
		PleaseLoginBindEmail:
			" Please log in and bind the third-party account in the personal center.",
		YouarevisitingDNAChronInternational:
			"You are visiting DNAChron International. We suggest you to visit DNAChron China according to your IP address.",
		Jumpto: "Jump to",
		Noprompt: "No prompt",
		yes: "Yes",
		no: "No",
		Save: "Save",
		Wearesorrythepageyourequestedcannotbefound:
			"We are sorry, the page you requested cannot be found.",
		BacktoHome: "Back to Home",
	},
	navbar: {
		YTree: "YTree",
		Ancestry: "Ancestry",
		ImportSample: "Import Data",
		Whatnews: "Updates",
		SignIn: "Sign in",
		SignUp: "Sign up",
		Notification: "Notification",
		PrivateMessage: "Private Message",
		AncestralTools: "Ancestry Tools",
		BrowseConfig: "Browse Config",
		PersonalCenter: "Personal Center",
		IndividualInformation: "Individual Information",
		SequenceData: "Sequence Data",
		Account: "Account",
		Logout: "Logout",
		TreeBrowseConfig: "Tree Browse Config",
		nodescountperpage: "nodes count per page",
		showdiscloseinformation: "Show Disclose Information",
		youcanviewyour:
			"You can view your private and authorized information after sign in.",
		showindividualname: "Show Individual Name",
		language: "Language",
	},
	index: {
		Trace: "Trace the footsteps of human history",
		Tracethehuman: `Trace the human journey with users worldwide by analyzing your genetic 
sequencing data with advanced algorithms to determine your exact location on 
the human ytree.`,
		Joinnow: "Explore YTree",
		Makeefficient: "Make efficient use of sequencing data",
		Ychromosomedata: `
			Y-chromosome data analysis can adaptively utilize 18M sequencing data, not only limited to the traditional estimation interval (8M) it can also give the range and 
			the probability of different segments;the higher the coverage of sequencing data,
			the more accurate the estimation;
			for whole genome
			sequence samples or branches,
			you can additionally utilize INDEL and MNP and
			increase the estimation accuracy.
			`,
		Preciseanalysis: "Precise analysis",
		Basedon:
			"Based on the efficient use of sequencing data, we can find more mutations and help to clarify the tree of each y-haplogroup, especially the branches with strong outbreaks, and accompany the coverage display to accurately analyze the position of your ytree.",
		Faston: "Fast on the tree",
		Afterthe:
			"After the staff successfully downloads the data provided by the user, the analysis will be completed and displayed on the ytree within 1-3 working days if there are no special abnormalities in the data, minimizing your waiting time and waiting anxiety.",
		Convenientquery: "Convenient query of isogg tree",
		Fortheisogg:
			"For the isogg numbers used by papers, testing organizations, scientific institutions, etc., we provide isogg tree and number queries to meet your query needs.",
		PrivacySecurity: "Privacy & Security",
		Wewill:
			"We will store your registration information, tester information, analysis result information and genetic information data separately on the cloud, and protect them in all aspects through reasonable and feasible security measures such as technology, hardware and management process.",
		exploreAncestral: "Explore Ancestry",
		ISOGGTree: "ISOGG Tree",
		YTree: "YTree",
		ImportData: "Import Data",
	},
	Footer: {
		FAQ: "FAQ",
		Blog: "Blog",
		CustomerService: "Customer Service Email",
		Officialcommunication: "Official communication group",
		AboutUs: "About Us",
		TermofService: "Terms of Service",
		PrivacyPolicy: "Privacy Policy",
		ContactCustomerService: "Contact Service",
		ContactService: "Message Customer Service",
		InternationalSite: "International Site",
		ChinaSite: "China Site",
		Site: "Site",
	},
	whatnews: {
		CreateDate: "Create Date",
		UpdateDate: "Update Date",
		YHaplogroup: "Y-HG",
		MTHaplogroup: "MT-HG",
		EstimatedAge: "TMRCA",
		Surname: "Surname",
		FirstName: "First Name",
		BirthYear: "Birth Year",
		Period: "Country or Culture",
		Origin: "Origin",
		Site: "Site",
		Population: "Population",
	},
	statusTips: {
		0: "success",
		10010: "Password incorrect",
		10070: "User does not exist",
	},
};

export default en;
