const zh_hans = {
	common: {
		login: "用户登录",
		UserRegistration: "用户注册",
		ResetPassword: "重置密码",
		Confirm: "确认",
		Cancel: "取消",
		Edit: "修改",
		PhoneNumber: "手机号",
		Email: "邮箱",
		UserName: "用户名",
		Password: "密码",
		VerificationCode: "验证码",
		ConfirmPassword: "确认密码",
		PleaseEnterThe: "请输入",
		EmailAlreadyExists: "已存在邮箱为",
		PleaseLoginBindEmail: "请登录后在个人中心绑定第三方账号。",
		YouarevisitingDNAChronInternational:
			"您正在访问基因志国际站。根据您的IP，我们推荐您前往体验基因志中国站。",
		Jumpto: "跳转",
		Noprompt: "不再提示",
		yes: "是",
		no: "否",
		Save: "保存",
		Wearesorrythepageyourequestedcannotbefound:
			"我们很抱歉，您请求的页面不存在。",
		BacktoHome: "返回主页",
	},
	navbar: {
		YTree: "父系树",
		Ancestry: "祖源",
		ImportSample: "导入数据",
		Whatnews: "用户动态",
		SignIn: "登录",
		SignUp: "注册",
		AncestralTools: "祖源工具",
		BrowseConfig: "浏览设置",
		PersonalCenter: "个人中心",
		IndividualInformation: "检测人信息",
		SequenceData: "我的数据",
		Account: "账户管理",
		Notification: "通知",
		PrivateMessage: "私信",
		Logout: "退出登录",
		TreeBrowseConfig: "树浏览设置",
		nodescountperpage: "每页节点数",
		showdiscloseinformation: "是否显示非公开信息",
		youcanviewyour: "登录后，可以在树上看到自己设置为授权或者保密的信息",
		showindividualname: "是否显示检测人名字",
		language: "语言",
	},
	index: {
		Trace: "追溯人类的历史足迹",
		Tracethehuman:
			"通过先进的算法分析您的基因测序数据，确定您在人类父系树上的具体位置，与全球用户共同追溯人类的历程。",
		Joinnow: "探索父系祖源",
		Makeefficient: "高效利用测序数据",
		Ychromosomedata:
			"Y染数据分析可自适应利用18M测序数据，而不仅限于传统估龄区间(8M)还可以给出范围以及不同区段的概率；测序数据覆盖率越高，估龄越精确；针对全基因组样本或者分支，可以额外利用上INDEL和MNP并增加估龄精度。",
		Preciseanalysis: "精准分析",
		Basedon:
			"基于高效的利用测序数据，我们能找到更多突变，有助于各个父系分支尤其是强烈爆发的分支理清树形，并配套覆盖率展示，精准分析您所处的Y树位置。",
		Faston: "快速上树",
		Afterthe:
			"工作人员成功下载用户提供的数据后，在数据无特殊异常的情况下，1-3个工作日内便能完成分析并显示在父系分化树上，尽可能减少您的等待时间和等待焦虑。",
		Convenientquery: "便捷查询isogg树",
		Fortheisogg:
			"针对论文、测试机构、科研机构等使用的isogg编号，我们提供isogg分化树形以及编号查询，满足您的查询需求。",
		PrivacySecurity: "隐私安全保障",
		Wewill:
			"我们会将您的注册信息、检测人信息、分析结果信息与遗传信息数据在云上分离存储，通过技术、硬件以及管理流程等合理可行的安全防护措施全方位保护。",
		exploreAncestral: "探索祖源",
		ISOGGTree: "ISOGG树",
		YTree: "父系树",
		ImportData: "导入数据",
	},
	Footer: {
		FAQ: "常见问题",
		Blog: "博客",
		CustomerService: "客服邮箱",
		Officialcommunication: "官方交流群",
		AboutUs: "关于我们",
		TermofService: "服务协议",
		PrivacyPolicy: "隐私政策",
		ContactCustomerService: "联系客服",
		ContactService: "联系客服",
		InternationalSite: "国际站",
		ChinaSite: "中国站",
		Site: "站点",
	},
	whatnews: {
		CreateDate: "创建时间",
		UpdateDate: "更新时间",
		YHaplogroup: "父系分支",
		MTHaplogroup: "母系分支",
		EstimatedAge: "估龄",
		Surname: "姓氏",
		FirstName: "名字",
		BirthYear: "出生年份",
		Period: "国家或文化",
		Origin: "籍贯",
		Site: "出土地",
		Population: "人群",
	},
	statusTips: {
		0: "成功",
		10010: "密码错误",
		10070: "用户不存在",
	},
};

export default zh_hans;
