<template>
  <router-view></router-view>
</template>

<script>
import { LangAndBasePathObj, checkLocalStorageConflict } from "@/utils";
export default {
  name: 'App',
  data() {
    return {
      meta_zh_hans: [
        {
          name: "keywords",
          content: "基因志,ytree,父系树,祖源",
        },
      ],
      meta_en: [
        {
          name: "keywords",
          content: "dnachron,ytree,y-haplogroup,ancestry",
        },
      ],
      routeObj2: null,
      meta: [],
    }
  },
  metaInfo() {
    return {
      meta: this.meta,
    };
  },
  created() {
    checkLocalStorageConflict();
    setTimeout(() => {
      this.tongJiFun();
    }, 2000);
  },
  watch: {
    $route(to, from) {

      let { lang } = LangAndBasePathObj(import.meta.env.VITE_APP_SITE_ENV, window.location.origin);

      //动态设置meta标签信息
      //获取当前路由信息
      this.routeObj2 = to;
      //如果不是首页，就清除description,写死在index.html里的desc是为了企业微信分享是能有desc,
      //清除desc 是为了搜索引擎索引不同页面有不同的desc，清除后索引会在内容里找desc
      let metaTags = document.getElementsByTagName("meta");
      for (let i = 0; i < metaTags.length; i++) {
        let nameAttribute = metaTags[i].getAttribute("name");
        if (nameAttribute === "description") {
          metaTags[i].remove();
        }
      }
      for (let i = 0; i < metaTags.length; i++) {
        let nameAttribute = metaTags[i].getAttribute("name");
        if (nameAttribute === "og:description") {
          metaTags[i].remove();
        }
      }
      for (let i = 0; i < metaTags.length; i++) {
        let propertyAttribute = metaTags[i].getAttribute("property");
        if (propertyAttribute === "description") {
          metaTags[i].remove();
        }
      } let desc_zh, desc_en
      if (this.routeObj2.name == "Index" || this.routeObj2.name == 'YTree') {
        if (this.routeObj2.name == 'YTree') {
          desc_zh = "父系树，父系祖源、估龄、ISOGG。";
          desc_en =
            "The Human Y-chromosome haplogroup tree. YTree that provides a comprehensive record of paternal ancestry, age estimation, and ISOGG number.";
        } else {
          desc_zh =
            "基因志 DNAChron 致力于提供高精度、高效率的父系树分析服务。以基因记录历史，探索每个人独一无二的故事。";
          desc_en =
            "DNAChron is a high accuracy and high efficiency Y-chromosome DNA haplogroup tree. Chronicle your unique story in the past.";
        }
        // 创建新的 meta 元素
        let newMeta = document.createElement("meta");
        newMeta.setAttribute("name", "description");
        if (lang == "zh-hans") {
          newMeta.setAttribute("content", desc_zh);
        } else {
          newMeta.setAttribute("content", desc_en);
        }
        // 创建新的 meta 元素
        let newMeta2 = document.createElement("meta");
        newMeta2.setAttribute("name", "og:description");
        if (lang == "zh-hans") {
          newMeta2.setAttribute("content", desc_zh);
        } else {
          newMeta2.setAttribute("content", desc_en);
        }
        // 创建新的 meta 元素
        let newMeta3 = document.createElement("meta");
        newMeta3.setAttribute("property", "description");
        if (lang == "zh-hans") {
          newMeta3.setAttribute("content", desc_zh);
        } else {
          newMeta3.setAttribute("content", desc_en);
        }
        // 获取文档头部
        let head = document.getElementsByTagName("head")[0];

        // 查找之前的 meta 元素
        let existingMeta = document.querySelector('meta[name="description"]');

        // 如果存在之前的 meta 元素，先将其移除
        if (!existingMeta) {
          // 将新的 meta 元素插入到头部
          head.appendChild(newMeta);
        }

        // 查找之前的 meta 元素
        let existingMeta2 = document.querySelector(
          'meta[name="og:description"]'
        );

        // 如果存在之前的 meta 元素，先将其移除
        if (!existingMeta2) {
          // 将新的 meta 元素插入到头部
          head.appendChild(newMeta2);
        }
        // 查找之前的 meta 元素
        let existingMeta3 = document.querySelector(
          'meta[property="description"]'
        );

        // 如果存在之前的 meta 元素，先将其移除
        if (!existingMeta3) {
          // 将新的 meta 元素插入到头部
          head.appendChild(newMeta3);
        }
      }


    }
  },
  methods: {
    //Google 百度 统计
    tongJiFun() {
      //国内站点 百度统计、国外站点google统计 production
      // 检查当前环境是否为生产环境
      console.log(import.meta.env.VITE_APP_ENV, '环境是什么');
      if (import.meta.env.VITE_APP_ENV === "production") {
        //百度
        if (LangAndBasePathObj(import.meta.env.VITE_APP_SITE_ENV, window.location.origin).site_region == 1) {
          console.log("进入百度统计");
          var _hmt = _hmt || [];
          (function () {
            var hm = document.createElement("script");
            hm.src =
              "https://hm.baidu.com/hm.js?16c97793c63c9ce3b6ec3564ccffe45c";
            var s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(hm, s);
          })();
          // if (!window._hmt) {
          //   window._hmt = [];
          // }
          // var hm = document.createElement("script");
          // hm.src = "https://hm.baidu.com/hm.js?16c97793c63c9ce3b6ec3564ccffe45c";
          // document.head.appendChild(hm);

          // // 手动发送页面视图
          // window._hmt.push(['_trackPageview', window.location.pathname]);

          // // 在路由变化时手动发送页面视图
          // this.$router.afterEach((to, from) => {
          //   window._hmt.push(['_trackPageview', to.fullPath]);
          // });
        } else {
          // 加载 Google Tag Manager 脚本
          const script = document.createElement("script");
          script.src = "https://www.googletagmanager.com/gtag/js?id=G-ZTD7N9SFY6";
          script.async = true;
          document.head.appendChild(script);
          // google
          window.dataLayer = window.dataLayer || [];

          function gtag() {
            dataLayer.push(arguments);
          }
          gtag("js", new Date());
          gtag("config", "G-ZTD7N9SFY6");
        }
      }
    },
  }
}
</script>

<style scoped></style>
