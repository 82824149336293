import { Log, UserManager, WebStorageStateStore } from "oidc-client-ts";

import { useUserInfoStore } from "@/store/modules/useUserInfoStore";
import { clearData } from "@/utils/index.js";

// Log.setLogger(console);
// Log.setLevel(Log.DEBUG);

// const authority = import.meta.env.VITE_APP_BASE_URL;
const authority = import.meta.env.VITE_APP_AUTH_BASE_URL;
const openIdConnectSettings = {
	authority,
	client_id: "dnachron",
	redirect_uri: `${authority.replace("/auth", "")}/authorize`,
	scope: "openid profile email",
	response_type: "code",
	userStore: new WebStorageStateStore({ store: window.localStorage }),
	automaticSilentRenew: true,
	silentRequestTimeoutInSeconds: 10, //如果在此时间内没有收到响应，请求将被认为是超时
	accessTokenExpiringNotificationTimeInSeconds: 60, //最后记得设置成还剩5分钟时刷新
};

const userManager = new UserManager(openIdConnectSettings);

userManager.events.removeSilentRenewError((e) => {
	console.log("Remove回调:当自动静默更新失败时引发。", e);
});

userManager.events.addSilentRenewError((e) => {
	// 当自动静默更新失败时引发
	console.log(e, "当自动静默刷新更新失败时引发");
	if (e.error === "invalid_grant") {
		clearData();
	}
});
export default userManager;
