import { defineStore } from "pinia";

import { getUserInfo } from "@/utils/index";

export const useUserInfoStore = defineStore("userInfo", {
	state: () => {
		return {
			userInfo: localStorage.getItem("userInfo")
				? getUserInfo(JSON.parse(localStorage.getItem("userInfo")))
				: {}, //用户信息
			tokenRefreshPromise: null, //全局判断token是否
		};
	},
	actions: {
		setUserInfo(userInfo) {
			this.userInfo = getUserInfo(userInfo);
			localStorage.setItem("userInfo", JSON.stringify(this.userInfo));
		},
		clearUserInfo() {
			this.userInfo = {};
			if (localStorage.getItem("userInfo")) {
				localStorage.removeItem("userInfo");
			}
		},
	},
});
